

/* Color Scheme and Max Screen Width */
:root {
  --background1-color: black;
  --white-color: #ffffff;
  --grey1-color: #BCBCBC;
  --grey2-color: #979797;
  --grey3-color: #191B21;
  --grey4-color: #1A1D22;
  --bluegrey-color: #272D32;
  --redpink-color: #F24462;
  --maxWidthForScreens: 1500px;
}

ul{
  padding-inline-start: 0px;
}


.app{
  background-color: black;
}

.home{
  background-color: var(--background1-color);
  color:white;
}

.home-body{
  max-width: var(--maxWidthForScreens);
  margin: auto;
}
.header{
  padding-top: 10px;
  display: flex;
  width:100%;
}
.header-name{
width:50%;
font-size: 20px;
color:white;
margin-top: -20px;
/* margin-left: 20px; */
}

.hrThin{
  border: 1px solid rgba(255, 255, 255, 0.55);
  margin-top: 15px;
}

.headerLogo  {
  width: 250px;
}
.header-nav{
font-family: Devant-Horgen;
width:50%;
text-align: right;
margin-top: 20px;
font-size: 25px;
margin-right: 20px;
}

.footer {
  margin-top: 60px;
  /* height: 200px; */
  border-top: 1px solid;
  border-color: rgba(255, 255, 255, 0.55)
}

.footer-navbar-flexbox{
display: flex;
align-items: center;
}

.footer-navbar-flexbox-middle{
  width:5%;
}

.footer-navbar-flexbox-right{
  
}

.footer-copyright {
  text-align: center;
}

.footer-copyright-p {
margin-top: 40px;
font-size: 12px;
font-weight: 300;
padding-bottom: 20px;
color: white

}

.navBar li{
  display: inline;
  font-family: Devant-Horgen;
  margin-bottom: 0px;
}
.navBar li a{
  text-decoration: none;
  color:white;
  margin:5px;
  font-size: 25px;
  font-weight: 200;
  position: relative;
}


.navBar li a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: var(--redpink-color);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.4s ease-in-out 0s;
  }

.navBar li a:hover::before {
    visibility: visible;
    transform: scaleX(1);
}

.socialMediaNavBar li{
  display: inline;
}

.socialMediaNavBar li a{
  text-decoration: none;
  color:white;
  margin:5px;
  font-size: 16px;
  font-weight: 200;
  position: relative;
}

.intro-container {
  margin-top: 20px;
  height: 100%;
  width: 90%;
  display: flex;
  margin: auto;
}

.intro-desc {
  width: 50%;
  text-align: left;
}

#intro-desc-top-p{
  padding-right: 50px;
  font-size: 15px;
  color: #ffffff;
}

#intro-desc-top-p2{
  padding-right: 50px;
  font-size: 13px;
  color: #ffffff;
  /* font-weight: 600; */
}

.intro-desc-information{
  color:white;
  margin-left: 50px;
  font-size: 20px;
}
#intro-desc-p{
  color: var(--grey2-color);
  font-size: 20px;
}
.intro-desc-contact-btn{
  background-color: var(--redpink-color);
  border-radius: 3px;
  font-size: 16px;
  padding: 15px;
  font-weight: 400px;
  color:white;
  border: 1px solid var(--redpink-color);
  outline:none;
}
.intro-desc-contact-btn:hover{
  background-color: #B5374D;
  color:rgb(221, 220, 220);
  border: 1px solid #B5374D;
  ;
}
.intro-video {
  width: 60%;
  margin-left: 10px;
  margin-right: 50px;
}

.intro-video2 {
  width: 70%;
 margin-right: 50px;
}

video {
  outline: none;
  object-fit: cover;
}

 .marketing-video-container{
  margin-bottom: 20px;
} 

.react-player__preview {
  height: 300px;
}
#pastClientsImg{
  margin: auto;
  display: block;
  height:25px;
}
#past-clients-desc{
  margin-top:10px;
  /* color:var(--grey2-color); */
  color: rgb(255, 255, 255);
  text-align: center;
  font-size:20px;
  font-weight: 500;
  font-size: 45px;
  font-family: Devant-Horgen;
  margin-bottom: 0px;
  /* margin-top: -10px; */
}
/* .client-adv-container {
  height: 600px;
  margin-bottom: 20px;
  text-align: center;
} */


.client-adv2-bg {
  
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 20px;
  margin-right: 20px; */
}

.client-adv2-bg-small {
  max-width: 1000px;
  width: 100%;
  /* margin-left: 20px;
  margin-right: 20px; */
  display: flex;
  justify-content: center;
  align-items: center;   
  padding: 50px;
}
.client-adv-desc {
background-color: var(--bluegrey-color);
position: absolute;
display: inline-block;
width: 400px;
/* margin-top: 150px; */
padding: 15px;
text-align: center;
border-radius: 5px;
}
.client-adv-line{
  color:var(--grey3-color);
  border-bottom: 1px solid var(--grey2-color);
  width: 80%;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
}
.client-adv-desc-p1 {
  font-size: 45px;
  font-family: Devant-Horgen;
  margin-bottom: 0px;
}
.client-adv-desc-p2 {
  font-weight: 300;
  color: var(--grey2-color);
  font-size: 20px;
}
.client-adv-desc-p2-white {
  font-weight: 300;
  color: var(--white-color);
  font-size: 20px;
}

.case-study-demo-container{
  margin-top: 30px;
  max-width: 80%;
  margin: auto;
}

.case-study-header {
  text-align: center;
  color: white;
  font-size: 45px;
  font-family: Devant-Horgen;
  margin-bottom: 0px;
}
.casestudy-demo-container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 50px;
}
.case-study-demo-info {
  width: 300px;
  margin-right: 20px;
  margin-left: 20px;
  padding: 20px;
  border-radius: 5px;
  background-color: var(--grey3-color);
}

.case-study-demo-image-container{
  display: flex;
  width:100%;
  align-items: center;
  justify-content: center;
}

.case-study-demo-image {
  width:100%;
  height:200px;
  margin-bottom: 10px;
  cursor: pointer;
}

i.link.icon, i.link.icons{
  color: #F5F5F5;
}

i.link.icon:hover, i.link.icons:hover{
  color:var(--redpink-color);
}

.case-study-demo-image-play-icon{
  float:right;
  color: #F5F5F5;
  position:absolute;
  
  padding-bottom: 70px;
}

.case-study-demo-title {
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  /* width: 80%; */
}
.case-study-demo-content {
  font-size: 13px;
  color: var(--grey1-color);
  font-weight: 300;
  margin-top: 10px;
  overflow: hidden;
  display: -webkit-box;
  /* -webkit-line-clamp: 10;
  -webkit-box-orient: vertical; */
}

.casestudy-demo-view-more-container{
  margin-top: 20px;
  text-align: center;
}

#services-start-today{
  color:var(--grey2-color)
}
.services-card{
  margin:auto;
  padding: 30px;
  background-color: var(--grey3-color);
  border-radius: 10px;
  width:300px;
}
.service-line{
  color:var(--grey3-color);
  border-right: 1px solid var(--grey2-color);
  height:10px;
}
.service-line-container{
  margin: auto;
  width: 2px;
}


.testimonials-container {
  width: 750px;
  margin: auto;
}
.testimonials-comments {
  background-color: var(--grey3-color);
  z-index: 1;
  position: absolute;
  width: 367px;
  margin-top: 40px;
  margin-left: 85px;
  border-radius: 5px;
  text-align: left;
  padding: 30px;
  font-size: 13px;
}
.carousel-container {
  height: 500px;
}
.carousel .slider {
  height: 400px;
}
.testimonials-image {
  background-color: var(--white-color);
  width: 290px;
  height: 340px;
  border-radius: 5px;
  margin-right: 100px;
  margin-bottom: 200px;
  float: right;
}

.testimonial-images{
  object-fit: cover;
  height:100%;
  border-radius: 5px;
}



#testimonial-comment-client-name{
  display: inline;
  margin-right: 20px;
}
#testimonial-comment-client-title{
  display: inline; 
  color: var(--grey2-color);
  font-size: 12px;
}
.questions-container {
  text-align: left;
  /* display: flex; */
  justify-content: center;
}

.questions-header{
  text-align: center;
  font-size: 45px;
  font-family: Devant-Horgen;
  margin-bottom: 0px;
}

.questions-div {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  max-width: 700px;
}
.question-div {
  background-color: var(--grey3-color) ;
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 5px;
}
.question-div:hover {
  background-color: var(--bluegrey-color)
}
.question-span {
  font-size: 15px;
  font-weight: 700;
}
.question-answer-span {
  font-size: 15px;
  font-weight: 400;
  padding-top: 20px;
  color: var(--white-color)
}
.question-chevron-span {
  float: right
}
.carousel .slider{
  height:400px;
}
.past-client-logos-container{
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  flex-wrap: wrap;
  margin:auto;
  justify-content: center;
  align-content: center;
  max-width: 1000px;
  width: 90%;
}
.past-client-logos{
  /* max-width: 70px; */
  height: 35px;
  margin-top: 25px;
  margin-left:20px;
  margin-right:20px;
  
}

.past-client-logos-small{
  /* max-width: 70px; */
  height: 25px;
  margin-top: 35px;
  margin-left:20px;
  margin-right:20px;
}

.past-client-logos-small2{
  /* max-width: 70px; */
  height: 25px;
  margin-top: 37px;
  margin-left:20px;
  margin-right:20px;
}

.past-client-logos-small3{
  /* max-width: 70px; */
  height: 35px;
  margin-top: 32px;
  margin-left:20px;
  margin-right:20px;
}
.past-client {
    margin-top: -14px;
    margin-left: 10px;
    margin-right: 10px;
}
#cal-optima {
    height: 120px;
}
#CalCCLogo {
    height: 120px;
}
#Coffee{
    height:120px;
}
.dbw {
    height: 35px;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
}

#stateFundLogo{
  height: 45px;
  margin-top: 23px;
}

#sIALogo{
  height: 45px;
}

#unileverLogo{
  height:45px;
  margin-top: 14px;
}

#cCCLogo{
  height: 45px;
}


.about-us-bottom-img{
width: 60%;
margin: auto;
display: block;
}

.about-page{
  width: 90%;
  margin: auto;
  max-width: var(--maxWidthForScreens);
  background-color: black;
  color: white;
}

.about-us-section{
  text-align: center;
  width: 100%;
  margin: auto;

}

.about-us-title{
  font-size: 45px;
  font-family: Devant-Horgen;
  margin-bottom: 15px;
  text-decoration: underline;
  text-decoration-color: #F24462;
}

.our-story-container-line{
  display: inline-block;
  border-bottom: white 1px solid;
  width: 70px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 8px;
}

.our-story-container-title{
  display: inline-block;
  font-size:20px;
  margin-top: 10px;
}

.about-us-paragraph{
  width: 100%; 
  text-align: left;
  font-size: 16px;
}

.about-us-paragraph-container{
  display: flex;
  justify-content: center;
}

.about-us-quote{
  font-style: italic;
  display: inline-block;
  max-width: 500px;
  margin: auto;
  text-align: center;
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.our-staff-title{
  font-size: 45px;
  font-family: Devant-Horgen;
  margin-bottom: 10px;
  text-align: center;
}

.our-staff-flexbox-container{
  display: flex;
  width: 100%;
  justify-content: center;
  /* align-items: center; */
  margin-top: 15px;
}

.our-staff-flexbox-container-left{
  width:30%;
}

.our-staff-flexbox-container-name-1{
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 20px;
}

#our-staff-hr{
  margin-bottom: 70px;
  margin-top: 50px;
}

.our-staff-flexbox-container-name-2{
  font-weight: 600;
  font-size: 20px;

}


.our-staff-flexbox-container-left-image-container{
  display: flex;
  width: 100%;
}


.expertise-header {
  font-size: 45px;
  font-family: Devant-Horgen;
  margin-bottom: 0px;
}



.carolBioPics{
  height:150px;

}

.our-staff-flexbox-container-right{
  padding-left: 20px;

}

.our-staff-flexbox-container-bio{
  font-size: 16px;
  max-width: 100%;

}

.our-staff-flexbox-container-left-image{
  height:250px
}

.gleeson-signature{
  font-family: 'Great Vibes', cursive;
}
.case-study-page {
  max-width: var(--maxWidthForScreens);
  margin: auto;
}
.case-study-page-intro-container{
  display: flex;
  height: 100%;
  /* max-width: 1100px; */
  margin: auto;
  align-content: center;
  justify-content: center;
  /* margin-bottom: 100px; */
}



.case-study-intro-container-description{
  color:white;
  width:30%;
  margin-left: 50px;
  font-size: 45px;
}



input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: var(--redpink-color);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

/* This will stick an item to the right side of it's div */
.case-study-intro-container-actual-image{
  position:absolute;
  right:0;
  width:800px;
}

.case-study-page-top-three-studies{
  display: flex;
  width: 100%;
  justify-content: center;
  height:650px
}

.case-study-page-top-three-studies-card-container{
  width: 33%;
}

.case-study-page-top-three-studies-card{
  background-color: var(--grey3-color);
  color:white;
  border-radius: 10px;
  width:300px;
  padding:20px;
  margin: auto;
}

.case-study-page-top-three-studies-card-image{
  width: 100%;
}

.case-study-page-top-three-studies-card-button{
  background-color: var(--redpink-color);
  border-radius: 3px;
  font-size: 16px;
  padding: 15px;
  color:white;
  border: 1px solid var(--redpink-color);
  border: none;
  outline:none;
  display: block;
  margin: auto;
}

.case-study-page-top-three-studies-card-button:hover{
  background-color: #B5374D;
  color:rgb(221, 220, 220); 
}

.case-study-page-top-three-studies-card-client{
  color: var(--grey2-color);
  font-size: 20px;
}

.case-study-page-top-three-studies-card-title{
  font-size: 25px;
}

.case-study-page-single-study-section{
  color:white;
  width: 80%;
  margin:auto;

}
.case-study-page-single-study-section-title{
  font-size: 35px;
  margin-left: 50px;
}

.case-study-page-single-study-section-client{
  font-size: 25px;
  color: var(--grey2-color);
  margin-left: 50px;
}

.case-study-page-single-study-section-image{
  margin: auto;
  display: block;
  width:80%;
}

.case-study-page-single-study-section-overview{
  font-size: 16px;
  width: 80%;
  margin:auto;
  padding-top: 20px;
  padding-bottom: 50px;
}

.services-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.particles{
  position: absolute;
  margin-top: 100px;
}

#tsparticles{
  height: 500px;
  width: 1500px;
}


.services-flex-box-cirlces-container{
  display: flex;
  width: 100%;
  height: 500px;
  justify-content: center;
  /* margin-top: 50px; */

}

.services-flex-box-cirlce-1{
  background-color: var(--grey3-color);
  border-radius: 100%;
  display: flex;
  justify-content: center;  
  align-items: center;
  width: 230px;
  height: 230px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 40px;
  opacity: .8;
}


.services-flex-box-cirlce-1:hover{
  /* animation: shake 1.5s; */
  animation: shake 1.5s;
  animation-iteration-count: infinite;
  opacity: 1;
  cursor: pointer;
}


.services-flex-box-cirlce-2{
  background-color: var(--grey3-color);
  border-radius: 100%;
  display: flex;
  justify-content: center;  
  align-items: center;
  width: 230px;
  height: 230px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 200px;
  opacity: .8;
}

.services-flex-box-cirlce-2:hover{
  animation: shake 1.5s;
  animation-iteration-count: infinite;
  opacity: 1;
  cursor: pointer;
}

.services-flex-box-cirlce-3{
  background-color: var(--grey3-color);
  border-radius: 100%;
  display: flex;
  justify-content: center;  
  align-items: center;
  width: 230px;
  height: 230px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 20px;
  opacity: .8;

}


.services-flex-box-cirlce-3:hover{
  animation: shake 1.5s;
  animation-iteration-count: infinite;
  opacity: 1;
  cursor: pointer;
}

.services-flex-box-cirlce-4{
  background-color: var(--grey3-color);
  border-radius: 100%;
  display: flex;
  justify-content: center;  
  align-items: center;
  width: 230px;
  height: 230px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 200px;
  opacity: .8;
}


.services-flex-box-cirlce-4:hover{
  animation: shake 1.5s;
  animation-iteration-count: infinite;
  opacity: 1;
  cursor: pointer;
}

.services-flex-box-cirlce-5{
  background-color: var(--grey3-color);
  border-radius: 100%;
  display: flex;
  justify-content: center;  
  align-items: center;
  width: 230px;
  height: 230px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  opacity: .8;

}


.services-flex-box-cirlce-5:hover{
  animation: shake 1.5s;
  animation-iteration-count: infinite;
  opacity: 1;
  cursor: pointer;
}


@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.services-flex-box-cirlce-title{
  color: white;
  
  font-size: 20px;
}




video{
  outline: none;
  object-fit: cover;
}


.home-and-cs-header-title{
  font-size: 55px;
  font-family: Devant-Horgen;
  margin-bottom: 10px;
  line-height: 100%;
  color: white;
}

.ui.modal{
  width:50%;
}

.ui.modal>.content{
  background-color: var(--grey3-color);
  color:white;
}

.contactUs-bg {
  background-image: url("./images/contact-bg2.png")  ;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* Changed width to 90% and added margin, justify-content, max-width, and border-radius*/
  width: 90%;
  max-width: var(--maxWidthForScreens);
  height: 600px;
  margin: auto;
  justify-content: center;
  border-radius: 5px;
}

.contactUs-container {
  display: flex;
  flex-direction: row

}

.contactUs-leftDiv {
width: 50%;
}

.contactus-welcome-div {
  margin-left: 50px;
  margin-right: 20px;
  margin-top: 20px;
  color: white;
}

.contactUs-welcome-header {
  font-weight: 300;
  font-size: 45px;
  font-family: Devant-Horgen;
  margin-bottom: 10px;
}

.contactUs-welcome-body {
  font-weight: 400;
  font-size: 18px;
}



.contactUs-form {
  width: 40%;
  max-width: 300px;
  z-index: 3;
  background-color: #272D32;
  height: 50%;
  margin-top: 30px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 2px 5px 20px rgb(150, 150, 150,.50);
}

.triangle-bottomright {
  width: 0;
  height: 1;
  z-index: 0;
  border-bottom: 200px solid #272D32;
  border-left: 100vw solid transparent;
  float: right;
  margin: -125px;
}

.triangle-container{
  height: 300px;
  position:relative;
  bottom: 0; 
  right: 0;
}

.contactUs-form {
  width: 40%;
  max-width: 300px;
  z-index: 3;
  background-color: var(--bluegrey-color);
  height: 50%;
  margin-top: 30px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 2px 5px 20px rgb(150, 150, 150,.50);
}

.contactUs-form-label {
  color: white;
  font-size: 13px;
  font-weight: 300;
}

.contactUs-form-input {
  background-color: var(--bluegrey-color);
  color: white;
  outline: none;
  margin-top: 5px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  width: 100%;
}

.contactUs-form-input:focus {
  outline: none;
}

.contact-form-radio {
  outline: none;
  color: var(--redpink-color);
  background-color: var(--redpink-color);

}
input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: var(--bluegrey-color);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid white;
}
input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: var(--redpink-color);
  content: '';
  display: inline-block;
  visibility: visible;
}

.contact-form-radio:focus {
  outline: none;
}

textarea {
  resize: none;
}

.contactUs-Form-btn {
  background-color: var(--redpink-color);
  border-radius: 3px;
  font-size: 16px;
  width: 100%;
  padding: 8px;
  font-weight: 400px;
  color:white;
  border: 1px solid var(--redpink-color);
  outline:none;
  margin-top: 20px;
}
.contactUs-Form-btn:hover{
  background-color: #B5374D;
  color:rgb(221, 220, 220);
  border: 1px solid #B5374D;
  ;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px   var(--bluegrey-color) inset !important;
    -webkit-text-fill-color: rgb(255, 255, 255) !important;
}

.contact-Us-Hr{
  border: 1px solid var(--redpink-color);
}

.insightsPage{
  width: 100%;
}

.allInsights-container {
  width:90%;
  margin: auto;
  margin-bottom: 20px;
  max-width: var(--maxWidthForScreens);
}

.allInsights-header {
  color: white;
  font-size: 45px;
  font-family: Devant-Horgen;
  margin-bottom: 0px;
  text-decoration: underline;
  text-decoration-color: #F24462;
} 

.insights-card-container{
  padding: 10px;
  cursor: pointer;

}

.insights-card-container:hover{
background-color: rgb(36, 36, 36);
}

.insights-card-title {
  font-size: 20px;
  font-weight: 600px;
  color: white;
}

.insights-card-article {
  font-size: 13px;
  font-weight: bold;
  font-weight: 200px;
  margin-top: -10px;
  color: var(--grey2-color);
}

.insights-card-p-div {
  border-left: 3px solid  #B5374D;
  margin-left: -7px;
  padding-left: 5px;
}

.insights-card-p-tag {
  color: white;
  font-size: 15px;
  font-weight: 600px;
}

.insights-card-p-tag-content {
  color: white;
  font-size: 15px;
  font-weight: 600px;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.insights-card-date {
  font-size: 13px;
  font-weight: 600px;
  color: white;
  margin-top: -10px;
}

.insights-card-div{
  border-bottom: 1px solid rgba(255, 255, 255, 0.55) ;
  background-color: rgba(255, 255, 255, 0.55);
  margin-left: 10%;
  margin-right: 10%;
}



.insight-container {
  width: 90%;
  margin: auto;
}

.insight-card-container{
  padding: 10px;
}

.insight-card-keyInsights {
  font-size: 15px;
  font-weight: 600px;
  color: white;
}

.noInsights-div {
  width: 100%;
  height: 500px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}

.css-9ebb9a{
  margin-right: 20px;
}

.white-text{
  color: white;
}


.loader{
  color: white;
  font-size: 30px;
  font-family: Devant-Horgen;
  margin-bottom: 0px;
  width: 1100px;
}

.insight-button{
  background-color: var(--redpink-color);
  border-radius: 3px;
  font-size: 12px;
  padding: 10px;
  color:white;
  border: 1px solid var(--redpink-color);
  border: none;
  outline:none;
  margin-bottom: 10px;
  margin-top: 5px;
}

.insight-button:hover{
  background-color: #B5374D;
  color:rgb(221, 220, 220); 
}


/* responsive styling */

@media only screen and (max-width: 1300px) {

  .our-staff-flexbox-container-right{
    padding-left: 20px;
  }
  

  .our-staff-flexbox-container-left{
    width: 40%;
    margin-left: 0px;
  }


  .services-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .particles{
    position: absolute;
    margin-top: 70px;
  }
  
  
  .services-flex-box-cirlces-container{
    display: flex;
    width: 100%;
    height: 500px;
    justify-content: center;
    margin-bottom: 0px;
  
  }
  
  .services-flex-box-cirlce-1{
    background-color: var(--grey3-color);
    border-radius: 100%;
    width: 230px;
    height: 230px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 40px;
  }
  
  .services-flex-box-cirlce-2{
    background-color: var(--grey3-color);
    border-radius: 100%;
    width: 230px;
    height: 230px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 200px;
  }
  
  .services-flex-box-cirlce-3{
    background-color: var(--grey3-color);
    border-radius: 100%;
    width: 230px;
    height: 230px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 20px;
  }
  
  .services-flex-box-cirlce-4{
    background-color: var(--grey3-color);
    border-radius: 100%;
    width: 230px;
    height: 230px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 200px;
  }

  .services-flex-box-cirlce-5{
    background-color: var(--grey3-color);
    border-radius: 100%;
    width: 230px;
    height: 230px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
  }
  
  
  .services-flex-box-cirlce-title{
    color: white;
    margin-top: 40px;
  }

  #intro-desc-p{
    color: var(--grey2-color);
  }
  #intro-desc-top-p{
    padding-right: 50px;
  }
  
}

@media only screen and (max-width: 1200px) {
  #intro-desc-top-p{
    font-size: 13px;
  
  }
  
  .services-flex-box-cirlce-1{
    background-color: var(--grey3-color);
    border-radius: 100%;
    width: 225px;
    height:225px;
    margin-left: 0px;
    margin-right: -10px;
    margin-top: 20px;
    padding: 5px;
  }
  .services-flex-box-cirlce-2{
    background-color: var(--grey3-color);
    border-radius: 100%;
    width: 225px;
    height:225px;
    margin-left: -10px;
    margin-right: -10px;
    padding: 5px;
  }
  .services-flex-box-cirlce-3{
    background-color: var(--grey3-color);
    border-radius: 100%;
    width: 225px;
    height:225px;
    margin-left: -10px;
    margin-right: -10px;
    padding: 5px;
  }

  .services-flex-box-cirlce-4{
    background-color: var(--grey3-color);
    border-radius: 100%;
    width: 225px;
    height:225px;
    margin-left: -10px;
    margin-right: -10px;
    padding: 5px;
  }

  .client-adv2-bg{
    width: 100%;
  }

  .services-flex-box-cirlce-5{
    background-color: var(--grey3-color);
    border-radius: 100%;
    width: 225px;
    height:225px;
    margin-left: -10px;
    margin-right: -10px;
    padding: 5px;
  }
}

@media only screen and (max-width: 1150px) {

#intro-desc-top-p{
  margin: auto;
  padding: 0px;
}

#intro-desc-p{
  font-size: 14px;
}

}

@media only screen and (max-width: 1100px) {
  .intro-container {
    margin-top: 20px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .intro-video {
    width: 90%;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 0px;
    margin-top: 15px; 
  }

  .home-and-cs-header-title{
    font-size: 45px;
    margin-bottom: 20px;
    line-height: 100%;
    color: white;
    text-align: center;
    margin-left: -40px;
  }

  .intro-desc {
    width: 100%;
    text-align: left;
  }
  
  #intro-desc-top-p{
    padding-right: 0px;
    margin: auto;
    font-size: 15px;
    width: 80%;
  }
  
  #intro-desc-top-p2{
    padding-right: 0px;
    font-size: 13px;
    text-align: center;
  }
  
  .intro-desc-information{
    margin-left: 0px;
    color:white;
    font-size: 20px;
  }
  #intro-desc-p{
    color: var(--grey2-color);
    font-size: 20px;
  }
  .intro-desc-contact-btn{
    background-color: var(--redpink-color);
    border-radius: 3px;
    font-size: 16px;
    padding: 15px;
    font-weight: 400px;
    color:white;
    border: 1px solid var(--redpink-color);
    outline:none;
  }
  .intro-desc-contact-btn:hover{
    background-color: #B5374D;
    color:rgb(221, 220, 220);
    border: 1px solid #B5374D;
    ;
  }
  .intro-video {
    width: 80%;
    margin-left: 0px;
    margin-right: 0px;
    margin: auto;
    margin-top: 30px;
  }
  

  }




/* Tablet */

@media only screen and (max-width: 1030px) {

  .marketing-video-container{
    margin-bottom: 20px;
  } 

  .header{
    width: 90%;
  }

  .header-nav{
    padding-right: 0px;
    width: 70%;
}


  .past-client-logos-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .past-client-logos{
    height: 30px;
    margin:20px;
  }

  #intro-desc-p{
    color: var(--grey2-color)
  }
  .intro-desc-contact-btn{
    background-color: var(--redpink-color);
    border-radius: 3px;
    font-size: 15px;
    padding: 15px;
    font-weight: 300px;
    color:white;
    border: 1px solid var(--redpink-color);
    border: none;
    outline:none;
  }
 
  
  .intro-video2 {
    width: 90%;
    margin: auto;
    margin-bottom: 40px;
    margin-top: -60px; 
  }



  /* .case-study-page-intro-container{
    display: flex;
    height: 100%;
    width:90%;
    margin-bottom: 20px;
    flex-direction: column-reverse;
    margin-top: 100px;
  }

  .case-study-demo-image{
    height:100%;

  }
  

  .client-adv2-bg-small {
    width: 100%;
  }
  


  .case-study-intro-container-description{
    color:white;
    width:100%;
    margin-left: 0px;
    margin-top: -50px;
    font-size: 45px;
    text-align: center;
  } */

  .expertise-header {
    padding-top: 20px;
    font-family: Devant-Horgen;
  }

  .services-flex-box-cirlces-container{
    display: flex;
    width: 100%;
    height: 1300px;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 0px;
  }
  
  .services-flex-box-cirlce-1{
    background-color: var(--grey3-color);
    border-radius: 100%;
    width: 225px;
    height: 225px;
    margin-left: 300px;
    margin-right: 15px;
    
    margin-top: -100px;
    padding: 5px;
  }
  
  .services-flex-box-cirlce-2{
    background-color: var(--grey3-color);
    border-radius: 100%;
    width: 225px;
    height:225px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 20px;
    padding: 5px;
  }
  
  .services-flex-box-cirlce-3{
    background-color: var(--grey3-color);
    border-radius: 100%;
    width: 225px;
    height:225px;
    margin-left: 300px;
    margin-right: 15px;
    margin-top: 20px;
    padding: 5px;
  }
  
  .services-flex-box-cirlce-4{
    background-color: var(--grey3-color);
    border-radius: 100%;
    width: 225px;
    height:225px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 20px;
    padding: 5px;
  }

  .services-flex-box-cirlce-5{
    background-color: var(--grey3-color);
    border-radius: 100%;
    width: 225px;
    height:225px;
    margin-left: 300px;
    margin-right: 15px;
    margin-top: 20px;
    padding: 5px;
  }
  
  
  .services-flex-box-cirlce-title{
    color: white;
    margin-top: 20px;
    font-size: 22px;
  }

  .loader{
    color: white;
    font-size: 30px;
    font-family: Devant-Horgen;
    margin-bottom: 0px;
    width: 900px;
  }
  
  .our-staff-flexbox-container-bio{
    font-size: 15px;
    max-width: 100%;
  
  }

  .our-staff-flexbox-container-left-image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    width: 100%;
}

  .our-staff-flexbox-container{
    display: flex;
    flex-direction: column;
    width:100%;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
  }

  .our-staff-flexbox-container-left {
    width: 100%;
    text-align: center;
  }

  .our-staff-flexbox-container-right {
    width: 100%;
    text-align: left;
}

}

/*header-nav was having resizing issues because of the flexbox constraints*/
  @media only screen and (max-width: 850px) {

    .about-page {
      width: 100%;
      /* margin: auto; */
      max-width: var(--maxWidthForScreens);
      background-color: black;
      color: white;
  }

    .casestudy-demo-container {
      flex-direction: column;
    }
    .case-study-demo-info {
      margin-top: 20px;
      width: 50%; 
      margin-left: auto;
       margin-right: auto;
    }

    .header-nav{
        width:70%;
        text-align: right;
        margin-top: 20px;
        font-size: 20px;
        margin-right: 0px;
    }

    .header-name{
      width:30%;
      font-size: 20px;
      color:white;
      margin-top: -20px;
      }

      .carousel-container {
        height: 500px;
      }
      .carousel .slider {
        height: 400px;
      }

      .loader{
        color: white;
        font-size: 30px;
        font-family: Devant-Horgen;
        margin-bottom: 0px;
        width: 800px;
      }

      .our-staff-title{
        font-size: 40px;
        margin-bottom: 50px;
        text-align: center;
      }
      
      .our-staff-flexbox-container{
        display: flex;
        flex-direction: column;
        width:100%;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
      }
      
      .our-staff-flexbox-container-left{
        width:100%;
        text-align: center;
      }
      
      .our-staff-flexbox-container-name{
        margin-right: 280px;
        font-size: 20px;
      }
      
      .our-staff-flexbox-container-title{
        margin-right: 365px;
        font-size: 18px;
        margin-top: 15px
      }
      
      
      .our-staff-flexbox-container-right{
        width: 100%;
        text-align: left;
      }
      
      .our-staff-flexbox-container-bio{
        margin-top: 10px;
        margin-bottom: 30px;
        margin-left: 20px;
        margin-right: 20px;
      }
      
      .our-staff-flexbox-container-left-image{
        height:250px;
        padding: 20px;
        /* width: 100% */
      }
      .about-us-paragraph{
        text-align: left;
        /* font-size: 17px; */
        margin-top: 20px;
        margin-bottom: 100px;
        margin-left: 50px;
        margin-right: 50px;
      }
      .about-us-quote{
        margin: auto;
        width: 90%;
        text-align: center;
        font-size: 15px;
        margin-top: 20px;
        margin-bottom: 100px;
      }
  
      .about-us-section{
       
      }
      
      .our-staff-flexbox-container-left-image-container{
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
        width: 100%;
      }
  }





/* Phone */
@media only screen and (max-width: 750px){


  .home-and-cs-header-title{
    font-size: 35px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    line-height: 100%;
    color: white;
    text-align: center;
  }

  .header{
    padding-top: 10px;
    display: flex;
    width:100%;
    flex-direction: column;
  }

  .header-name{
    width:100%;
    font-size: 20px;
    color:white;
    margin-top: 10px;
    text-align: center;
    margin-left: auto;
    }
    .header-nav{
    width:100%;
    text-align: center;
    margin-top: -10px;
    font-size: 20px;
    }

    .past-client-logos-container{
      margin-top: 10px;

    }
  
   

    .case-study-demo-info {
      margin-top: 20px;
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }

    .client-adv-desc{
      padding: 5px;
     width: 200px;
    }

    .client-adv-desc-p1{
      font-size: 30px;
    }

    .client-adv-desc-p2{
      font-size:15px;
    }

    .client-adv-desc-p2-white{
      font-size:15px;
    }

    

 
    .testimonials-container {
      width: 500px;
      margin: auto;
    }
    .testimonials-comments {
      background-color: var(--grey3-color);
      z-index: 1;
      position: absolute;
      width: 200px;
      margin-top: 20px;
      margin-left: 80px;
      border-radius: 5px;
      text-align: left;
      padding: 10px;
      font-size: 12px;
    }
    .carousel-container {
      height: 500px;
    }
    .carousel .slider {
      height: 400px;
    }
    .testimonials-image {
      background-color: var(--white-color);
      width: 190px;
      height: 240px;
      border-radius: 5px;
      margin-right: 60px;
      float: right;
    }
    
    .testimonial-images{
      object-fit: cover;
      height:100%;
      border-radius: 5px;
    }
    #testimonial-comment-client-name{
      display: inline;
      margin-right: 20px;
    }
    #testimonial-comment-client-title{
      display: inline; 
      color: var(--grey2-color);
      font-size: 12px;
    }
  
    .our-story-container-line{
    display: inline-block;
    border-bottom: white 1px solid;
    width: 40px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 8px;
  }
  
  .our-story-container-title{
    
    display: inline-block;
    font-size: 20px;
  }
    .allInsights-container {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 20px;
    }
 
    .services-flex-box-cirlce-1{
      background-color: var(--grey3-color);
      border-radius: 100%;
      width: 225px;
      height: 225px;
      margin-left: 15px;
      margin-right: 15px;
      margin-top: -100px;
      padding: 5px;
    }
    
    .services-flex-box-cirlce-2{
      background-color: var(--grey3-color);
      border-radius: 100%;
      width: 225px;
      height:225px;
      margin-left: 15px;
      margin-right: 15px;
      margin-top: 20px;
      padding: 5px;
    }
    
    .services-flex-box-cirlce-3{
      background-color: var(--grey3-color);
      border-radius: 100%;
      width: 225px;
      height:225px;
      margin-left: 15px;
      margin-right: 15px;
      margin-top: 20px;
      padding: 5px;
    }
    
    .services-flex-box-cirlce-4{
      background-color: var(--grey3-color);
      border-radius: 100%;
      width: 225px;
      height:225px;
      margin-left: 15px;
      margin-right: 15px;
      margin-top: 20px;
      padding: 5px;
    }
  
    .services-flex-box-cirlce-5{
      background-color: var(--grey3-color);
      border-radius: 100%;
      width: 225px;
      height:225px;
      margin-left: 15px;
      margin-right: 15px;
      margin-top: 20px;
      padding: 5px;
    }
    
    .loader{
      color: white;
      font-size: 30px;
      font-family: Devant-Horgen;
      margin-bottom: 0px;
      width: 100%;
    }

}

@media only screen and (max-width: 600px){

  .contactus-welcome-div {
    margin-left: 20px;

  }

  .case-study-demo-content {
    font-size: 10px;
    color: var(--grey1-color);
    font-weight: 300;
    margin-top: 10px;
    overflow: hidden;
    display: -webkit-box;
    /* -webkit-line-clamp: 10;
    -webkit-box-orient: vertical; */
  }


  .case-study-demo-info {
    margin-top: 20px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .our-story-container-line{
  display: inline-block;
  border-bottom: white 1px solid;
  width: 40px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 8px;
}

.our-story-container-title{
  
  display: inline-block;
  font-size: 20px;
}

/* .client-adv2-bg{
  background-image: url("./images/client-adv-bg-square.png")  ;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 400px;
} */

.client-adv2-bg-small {
  padding: 5%;
}

}
@media only screen and (max-width: 450px){

.footer-navbar-flexbox-left {
    margin-left: 20px;
} 

.footer-navbar-flexbox-right {
    margin-left: 20px;
}
.testimonials-container {
  width: 100%;
  margin: auto;
}
.testimonials-comments {
  background-color: var(--grey3-color);
  z-index: 1;
  position: absolute;
  width: 200px;
  margin-top: 20px;
  margin-left: 25px;
  border-radius: 5px;
  text-align: left;
  padding: 10px;
  font-size: 12px;
}
.carousel-container {
  height: 500px;
}
.carousel .slider {
  height: 400px;
}
.testimonials-image {
  background-color: var(--white-color);
  width: 190px;
  height: 240px;
  border-radius: 5px;
  margin-right: 20px;
  margin-bottom: 200px;
  float: right;
}

.testimonial-images{
  object-fit: cover;
  height:100%;
  border-radius: 5px;
}
#testimonial-comment-client-name{
  display: inline;
  margin-right: 20px;
}
#testimonial-comment-client-title{
  display: inline; 
  color: var(--grey2-color);
  font-size: 12px;
}

}

@media only screen and (max-width: 400px){

  .testimonials-container {
    width: 100%;
    margin: auto;
  }
  .testimonials-comments {
    background-color: var(--grey3-color);
    z-index: 1;
    position: absolute;
    width: 200px;
    margin-top: 20px;
    margin-left: 25px;
    border-radius: 5px;
    text-align: left;
    padding: 10px;
    font-size: 12px;
  }
  .carousel-container {
    height: 500px;
  }
  .carousel .slider {
    height: 400px;
  }
  .testimonials-image {
    background-color: var(--white-color);
    width: 190px;
    height: 240px;
    border-radius: 5px;
    margin-right: 20px;
    margin-bottom: 200px;
    float: right;
  }
  
  .testimonial-images{
    object-fit: cover;
    height:100%;
    border-radius: 5px;
  }
  #testimonial-comment-client-name{
    display: inline;
    margin-right: 20px;
  }
  #testimonial-comment-client-title{
    display: inline; 
    color: var(--grey2-color);
    font-size: 12px;
  }

  .ui.modal {
    width: 90%;
}


.contactUs-container {
  flex-direction: column;
  align-items: center;
}

.contactUs-form {
  width: 80%;
  max-width: 300px;
  /* z-index: 3; */
  background-color: var(--bluegrey-color);
  
  margin-top: 30px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 2px 5px 20px rgb(150, 150, 150,.50);
}

.contactUs-leftDiv {
width: 90%;
}

.contactus-welcome-div {
margin-left: 0px;
margin-right: 0px;
}

.contactUs-bg {
  width: 100%;
  height: 800px;
}

/* .client-adv2-bg{
  height: 370px;
} */

}
